import { useSessionContext } from '@contexts/session'
import { LinguiJSMessageDescriptor } from '@lib/types/common'
import { Trans } from '@lingui/macro'
import classNames from 'classnames'
import Link from 'next/link'

type Props = {
  link: string
  text: LinguiJSMessageDescriptor
  isDark?: boolean
  hideMobileMenu?: () => void
}

/** i18n support added */
const NavbarNewLink = ({
  hideMobileMenu,
  link,
  text,
  isDark = false,
}: Props) => {
  const { loggedIn } = useSessionContext()
  return (
    <Link href={link} prefetch={false}>
      <a
        className={classNames(
          'text-xl md:text-lg font-500 md:font-600 md:align-middle md:py-2 md:mt-0 flex space-x-1',
          {
            'text-white': isDark,
            'text-grey hover:text-orange': !isDark,
            'mt-3': loggedIn,
          }
        )}
        onClick={hideMobileMenu}
      >
        <span>
          <Trans id={text.id} values={text?.values} />
        </span>
      </a>
    </Link>
  )
}
export default NavbarNewLink
